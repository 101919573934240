import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  SectionPageTitle,
  Container,
  MainPanel,
  LeftPanel,
  RightPanel,
  SectionImageBkg,
  BreadCrumb,
} from "../components/Section";
import {
  SidebarSticky,
  SidebarHeaderSticky,
  SidebarStickyTitle,
  SidebarStickyClose,
  SidebarStickyBody,
  SidbarStickyFooter,
  SidebarCard,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarCardBody,
  SearchForm,
  SidebarStickyToolbar,
  SidebarItemToolbar,
  SidebarStickyAction,
  SidebarStickyReset,
} from "../components/Sidebar";
import {
  Nodata,
  NodataTitle,
  NodataDesc,
  NodataMedia,
} from "../components/Nodata";
import ProductCardGridView from "../components/ProductCardGridView";
import { SearchIcon, DropArrowIcon, CloseIcon } from "../components/Icons";
import Pagination from "../components/Pagination";
import Badge from "../components/Badge";
import DefaultLinkButton from "../components/Button/DefaultLinkButton";
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import PrimaryButton from "../components/Button/PrimaryButton";

import "../components/RangeSlider/styles.less";
import TooltipSlider from "../components/RangeSlider";
import { getCommonItems } from "../utils";
import AutoCompleteInput from "../components/AutoComplete";

const HeroBanner = styled.section`
  position: relative;
  margin-top: 64px;
  padding: 40px 0;

  @media (min-width: 1200px) {
    margin-top: 100px;
    padding: 70px 0 60px 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const HeroCaption = styled.div`
  position: relative;
  z-index: 1;
  margin: 0;
  @media (min-width: 1200px) {
    height: 100%;
  }
`;
const HeroTitle = styled(SectionPageTitle)`
  color: #fff;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  @media (min-width: 576px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (min-width: 768px) {
    font-size: 42px;
    line-height: 52px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 58px;
  }
  @media (min-width: 1200px) {
    font-size: 54px;
    line-height: 64px;
  }
  @media (min-width: 1600px) {
    font-size: 60px;
    line-height: 70px;
  }
`;

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 768px) {
    &.grid-list {
      flex-direction: column;
      .product-item {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .card {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        text-align: left;
        .card-body {
          flex: 0 0 43%;
          max-width: 43%;
        }
        .card-footer {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          padding: 20px;
          .list-info {
            justify-content: flex-start;
          }
        }
      }
    }
    &.grid-view {
      flex-direction: row;
    }
  }
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 15px;
  }
`;
const ListBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
  .badge {
    margin: 3px;
  }
`;

const TopBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 20px -5px;

  @media (max-width: 991.98px) {
    margin: 0 0 20px;
    position: sticky;
    top: 64px;
    z-index: 998;
    background: #eee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  }
`;
const TopLeftBar = styled.div`
  position: relative;
  padding: 0 5px;

  flex: 0 0 auto;
  width: auto;
  max-width: none;
  @media (min-width: 992px) {
    display: none;
  }
`;
const Filter = styled.button`
  .text {
    + .icon {
      margin-left: 2px;
    }
  }
`;
const TopRightBar = styled.div`
  position: relative;
  padding: 0 5px;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

const ranges = {
  widthRange: [0, 120],
  lengthRange: [0, 300],
  heightRange: [0, 20],
  priceRange: [3, 500],
};

const defaultFilterCondition = {
  widthRange: [0, 120],
  lengthRange: [0, 300],
  heightRange: [0, 20],
  priceRange: [3, 500],
  tags: [],
};

const ShopMetalBuildingsPage = ({ location, data }) => {
  const totalProducts = data.allContentfulProduct.edges;
  const candidates = data.allContentfulProductCategories.edges.map(
    (c) => c.node.title
  );

  const metalBuildingCategory =
    data.allContentfulProductCategories.edges.find(
      (cat) => cat.node.title === "Metal Buildings"
    );

  const [showSticky, setShowSticky] = useState(true);
  const [layoutType, setLayoutType] = useState("list");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [offsetTop, setOffsetTop] = useState(0);
  const [totalTags, setTotalTags] = useState([]);

  const [filterCondition, setFilterCondition] = useState(
    defaultFilterCondition
  );
  const [sku, setSku] = useState("");
  const [category, setCategory] = useState("");
  const [widthRange, setWidthRange] = useState(
    defaultFilterCondition.widthRange
  );
  const [lengthRange, setLengthRange] = useState(
    defaultFilterCondition.lengthRange
  );
  const [heightRange, setHeightRange] = useState(
    defaultFilterCondition.heightRange
  );
  const [priceRange, setPriceRange] = useState(
    defaultFilterCondition.priceRange
  );
  const [tags, setTags] = useState(defaultFilterCondition.tags);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);

  const refreshPage = (pn, ps) => {
    const startIndex = (pn - 1) * ps;
    const endIndex = startIndex + ps;
    const pageProducts = filteredProducts.slice(startIndex, endIndex);
    setShownProducts(pageProducts);
  };

  useEffect(() => {
    const el = document.getElementById("main-content");

    const getContentTop = () => {
      const el = document.getElementById("main-content");
      const top = el.getBoundingClientRect().top;
      setOffsetTop(top + window.pageYOffset);
    };

    document.addEventListener("resize", getContentTop);
    getContentTop();

    let tTags = [];
    totalProducts.forEach((p) => {
      if (p.node.buildingTags) {
        tTags = [...tTags, ...p.node.buildingTags];
      }
    });
    const distinctArray = [...new Set(tTags.map((tag) => tag.trim()))];
    setTotalTags(distinctArray);

    return () => {
      document.removeEventListener("resize", getContentTop);
    };
  }, []);

  useEffect(() => {
    if (location.state?.tag) {
      setTags([location.state.tag]);
      setFilterCondition({
        ...filterCondition,
        tags: [location.state.tag],
      });
    }
  }, [location.state]);

  useEffect(() => {
    refreshPage(1, pageSize);
    setPageNo(1);
  }, [pageSize, filteredProducts]);

  useEffect(() => {
    refreshPage(pageNo, pageSize);
  }, [filteredProducts, pageNo]);

  useEffect(() => {
    const {
      widthRange: fcWidthRange,
      lengthRange: fcLengthRange,
      heightRange: fcHeightRange,
      priceRange: fcPriceRange,
      tags: fcTags,
    } = filterCondition;
    const ps = totalProducts.filter((p) => {
      const product = p.node;
      const width = parseFloat(product.width);
      const length = parseFloat(product.length);
      const height = parseFloat(product.height);
      const price = parseFloat(product.price);
      if (sku && sku !== product.skuNumber) return false;

      const categories = product.productCategories.map((cat) => cat.title);
      if (category && categories.indexOf(category) < 0) return false;
      
      if (width < fcWidthRange[0] || width > fcWidthRange[1]) return false;
      if (length < fcLengthRange[0] || length > fcLengthRange[1]) return false;
      if (height < fcHeightRange[0] || height > fcHeightRange[1]) return false;
      if (price < fcPriceRange[0] * 1000 || price > fcPriceRange[1] * 1000)
        return false;
      if (fcTags && fcTags.length) {
        if (!product.buildingTags || !product.buildingTags.length) return false;
        const commonTags = getCommonItems(fcTags, product.buildingTags);
        if (!commonTags || !commonTags.length) return false;
      }
      return true;
    });
    setFilteredProducts([...ps]);
    scrollToProductList();
  }, [sku, category, filterCondition]);

  const resetFilter = () => {
    setFilterCondition(defaultFilterCondition);
    setWidthRange(defaultFilterCondition.widthRange);
    setLengthRange(defaultFilterCondition.lengthRange);
    setHeightRange(defaultFilterCondition.heightRange);
    setPriceRange(defaultFilterCondition.priceRange);
    setTags(defaultFilterCondition.tags);
  };

  const applyFilter = () => {
    setFilterCondition({
      widthRange,
      lengthRange,
      heightRange,
      priceRange,
      tags,
    });
    if (window.innerWidth < 992) {
      setShowSticky(!showSticky);
    }
    scrollToProductList();
  };

  const scrollToProductList = () => {
    const offset = 120;
    if (window.pageYOffset > offsetTop - offset) {
      window.scrollTo({
        top: offsetTop - offset,
        behavior: "smooth",
      });
    }
  };

  const ToggleHelp = () => {
    setShowSticky(!showSticky);
  };

  if (typeof window !== `undefined`) {
    if (showSticky === true) {
      document.body.classList.remove("modal-open");
    } else {
      document.body.classList.add("modal-open");
    }
  }

  let filterCount = 0;
  if (sku) filterCount += 1;
  if (category) filterCount += 1;
  if (
    widthRange[0] !== ranges.widthRange[0] ||
    widthRange[1] !== ranges.widthRange[1]
  )
    filterCount += 1;
  if (
    lengthRange[0] !== ranges.lengthRange[0] ||
    lengthRange[1] !== ranges.lengthRange[1]
  )
    filterCount += 1;
  if (
    heightRange[0] !== ranges.heightRange[0] ||
    heightRange[1] !== ranges.heightRange[1]
  )
    filterCount += 1;
  if (
    priceRange[0] !== ranges.priceRange[0] ||
    priceRange[1] !== ranges.priceRange[1]
  )
    filterCount += 1;
  if (filterCondition.tags.length) filterCount += 1;

  return (
    <Layout location={location}>
      <Seo title={metalBuildingCategory.node.metaTitle} description={metalBuildingCategory.node.metaDescription} />
      <HeroBanner>
        <SectionImageBkg>
          <StaticImage
            src="../images/shop-metal-building.jpg"
            alt="Shop Metal Buildings"
          />
        </SectionImageBkg>
        <HeroCaption>
          <Container className="container">
            <BreadCrumb className="white">
              <Link to="/">Home</Link>
            </BreadCrumb>
            <HeroTitle>Shop Metal Buildings</HeroTitle>
          </Container>
        </HeroCaption>
      </HeroBanner>

      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-product"
      >
        <Container>
          <MainPanel id="main-content">
            <LeftPanel>
              <SidebarSticky
                className={
                  showSticky
                    ? "sidebar-sticky isSticky"
                    : "sidebar-sticky isSticky show"
                }
              >
                <SidebarHeaderSticky className="sidebar-header-sticky">
                  <SidebarStickyTitle>Filters</SidebarStickyTitle>
                  <SidebarStickyAction>
                    <SidebarStickyReset type="button" onClick={resetFilter}>
                      <DefaultLinkButton text="Reset" size="xs" name="default-link" />                      
                    </SidebarStickyReset>
                    <SidebarStickyClose onClick={ToggleHelp}>
                      <CloseIcon />
                    </SidebarStickyClose>
                  </SidebarStickyAction>
                </SidebarHeaderSticky>
                <SidebarStickyBody>
                  <SidebarCard className="sidebar-card">
                    <SidebarCardBody className="sidebar-body">
                      <SearchForm className="search-form">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="SKU Search"
                            onChange={(e) => setSku(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-default" type="button">
                              <span className="icon">
                                <SearchIcon />
                              </span>
                            </button>
                          </div>
                        </div>
                      </SearchForm>
                      <SearchForm className="search-form">
                        <AutoCompleteInput
                          candidates={candidates}
                          placeholder="Search by building type"
                          onCompleted={(selected) => setCategory(selected)}
                        />
                      </SearchForm>
                    </SidebarCardBody>
                  </SidebarCard>

                  <SidebarCard className="sidebar-card">
                    <SidebarCardHeader className="sidebar-header">
                      <SidebarCardTitle>Width (Ft)</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody className="sidebar-body">
                      <TooltipSlider
                        range
                        min={ranges.widthRange[0]}
                        max={ranges.widthRange[1]}
                        marks={{
                          [ranges.widthRange[0]]: ranges.widthRange[0],
                          [ranges.widthRange[1]]: ranges.widthRange[1],
                        }}
                        value={widthRange}
                        dragging
                        tipFormatter={(value) => value}
                        onChange={(v) => setWidthRange([...v])}
                      />
                    </SidebarCardBody>
                  </SidebarCard>

                  <SidebarCard className="sidebar-card">
                    <SidebarCardHeader className="sidebar-header">
                      <SidebarCardTitle>Length (Ft)</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody className="sidebar-body">
                      <TooltipSlider
                        range
                        min={ranges.lengthRange[0]}
                        max={ranges.lengthRange[1]}
                        marks={{
                          [ranges.lengthRange[0]]: ranges.lengthRange[0],
                          [ranges.lengthRange[1]]: ranges.lengthRange[1],
                        }}
                        value={lengthRange}
                        dragging
                        tipFormatter={(value) => value}
                        onChange={(v) => setLengthRange([...v])}
                      />
                    </SidebarCardBody>
                  </SidebarCard>

                  <SidebarCard className="sidebar-card">
                    <SidebarCardHeader className="sidebar-header">
                      <SidebarCardTitle>Height (Ft)</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody className="sidebar-body">
                      <TooltipSlider
                        range
                        min={ranges.heightRange[0]}
                        max={ranges.heightRange[1]}
                        marks={{
                          [ranges.heightRange[0]]: ranges.heightRange[0],
                          [ranges.heightRange[1]]: ranges.heightRange[1],
                        }}
                        value={heightRange}
                        dragging
                        tipFormatter={(value) => value}
                        onChange={(v) => setHeightRange([...v])}
                      />
                    </SidebarCardBody>
                  </SidebarCard>

                  <SidebarCard className="sidebar-card">
                    <SidebarCardHeader className="sidebar-header">
                      <SidebarCardTitle>Price(K)</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody className="sidebar-body">
                      <TooltipSlider
                        range
                        min={ranges.priceRange[0]}
                        max={ranges.priceRange[1]}
                        marks={{
                          [ranges.priceRange[0]]: `${ranges.priceRange[0]}K`,
                          [ranges.priceRange[1]]: `${ranges.priceRange[1]}K`,
                        }}
                        value={priceRange}
                        dragging
                        tipFormatter={(value) => value}
                        onChange={(v) => setPriceRange([...v])}
                      />
                    </SidebarCardBody>
                  </SidebarCard>

                  <SidebarCard className="sidebar-card">
                    <SidebarCardHeader className="sidebar-header">
                      <SidebarCardTitle>Most Searched Tags</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody className="sidebar-body">
                      <ListBadge>
                        {totalTags.map((item, i) => (
                          <Badge
                            key={i}
                            badgeText={item}
                            badeColor={
                              tags.indexOf(item) >= 0 ? "active" : "default"
                            }
                            size="sm"
                            onClick={() => {
                              let nTags = [];
                              if (tags.indexOf(item) >= 0) {
                                nTags = tags.filter((tag) => tag !== item);
                              } else {
                                nTags = [...tags, item];
                              }
                              setTags(nTags);
                            }}
                          />
                        ))}
                      </ListBadge>
                    </SidebarCardBody>
                  </SidebarCard>
                </SidebarStickyBody>
                <SidbarStickyFooter className="sidebar-footer-sticky">
                  <SidebarStickyToolbar className="sidebar-toolbar">
                    <SidebarItemToolbar className="sidebar-toolbar-item">
                      <button type="button" onClick={resetFilter}>
                        <DefaultLinkButton text="Reset" size="xs" name="default-link" />
                      </button>
                    </SidebarItemToolbar>
                    <SidebarItemToolbar className="sidebar-toolbar-item">
                      <button type="button" onClick={applyFilter}>
                        <PrimaryButton text="Apply" size="xs" />
                      </button>
                    </SidebarItemToolbar>
                  </SidebarStickyToolbar>
                </SidbarStickyFooter>
              </SidebarSticky>
            </LeftPanel>
            <RightPanel>
              <TopBar>
                <TopLeftBar>
                  <Filter type="button" onClick={ToggleHelp}>
                    <span className="text">Filter ({filterCount})</span>
                    <span className="icon">
                      <DropArrowIcon />
                    </span>
                  </Filter>
                </TopLeftBar>
                <TopRightBar>
                  <Pagination
                    scrollToStart={scrollToProductList}
                    count={filteredProducts.length}
                    pageNo={pageNo}
                    changePageNo={setPageNo}
                    pageSize={pageSize}
                    changePageSize={setPageSize}
                    layoutType={layoutType}
                    changeLayout={setLayoutType}
                  />
                </TopRightBar>
              </TopBar>
              {layoutType === "list" && (
                <ProductGrid className="grid-list">
                  {shownProducts.map((product, i) => (
                    <ProductGridItem key={i} className="product-item">
                      <ProductCardGridView product={product.node} />
                    </ProductGridItem>
                  ))}
                </ProductGrid>
              )}
              {layoutType === "grid" && (
                <ProductGrid className="grid-view">
                  {shownProducts.map((product, i) => (
                    <ProductGridItem key={i} className="product-item">
                      <ProductCardGridView product={product.node} />
                    </ProductGridItem>
                  ))}
                </ProductGrid>
              )}

              {!filteredProducts ||
                (filteredProducts.length === 0 && (
                  <Nodata>
                    <NodataMedia>
                      <StaticImage
                        src="../images/no-result.png"
                        alt="no-result"
                      />
                    </NodataMedia>
                    <NodataTitle className="h2 mb-15">
                      No Result Found
                    </NodataTitle>
                    <NodataDesc mb="30px">
                      <p>
                        We couldn't find any results that match your search.
                      </p>
                    </NodataDesc>
                  </Nodata>
                ))}
              <Pagination
                scrollToStart={scrollToProductList}
                count={filteredProducts.length}
                pageNo={pageNo}
                changePageNo={setPageNo}
                pageSize={pageSize}
                changePageSize={setPageSize}
                layoutType={layoutType}
                changeLayout={setLayoutType}
              />
            </RightPanel>
          </MainPanel>
        </Container>
      </Section>
    </Layout>
  );
};

export default ShopMetalBuildingsPage;

export const pageQuery = graphql`
  query ShopMetalBuildingsPageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
    allContentfulProductCategories(sort: { fields: title, order: ASC }) {
      edges {
        node {
          metaTitle
          metaDescription
          title
        }
      }
    }
  }
`;