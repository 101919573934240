import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const DefaultLinkBtn = styled(BaseButton)`		
	background-color:transparent;
	color:#373636;	
	box-shadow:none;
	& .icon{
		> svg{
			fill:#373636;
		}
	}
	&:hover{		
		color:#ed1c24;
		& .icon{
			> svg{
				fill:#ed1c24;
			}
		}
	}
`
const DefaultLinkButton = (props) => {
	const { icon, iconAfter, text, size, name } = props;
	return(
		<DefaultLinkBtn className={`btn ${size ? `btn-${size}` : ''} ${name ? `btn-${name}` : ''} `}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</DefaultLinkBtn>
	)
}


export default DefaultLinkButton

